<template>
	<v-parallax :src="require('@/assets/home-banner.jpg')" height="400" class="banner">
		<v-row align="center" justify="center">
			<v-col class="text-center" cols="12">
				<h1 class="display-1 banner-text">Daj się uwieść magicznemu światu romansów paranormalnych</h1>
				<div class="subheading text-right pr-10 ma-6">Natasha Lucas</div>
			</v-col>
		</v-row>
	</v-parallax>
</template>


<script>
export default {
	name: "HomeBanner",
};
</script>
<style scoped>
.banner {
	margin: -1px;
}
.subheading {
	font-family: "GreatVibes";
	font-size: 45px;
}
.banner-text {
	font-family: "Rallway" !important;
	font-size: 35px !important;
	text-transform: uppercase;
}
</style>